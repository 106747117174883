// assets manager
// accessible as window.yaru.assets
// built by special 'assets' task in gulp

const ASSETS_BASE_URL = '/assets/';

class Assets {
    constructor(list, version) {
        this.list    = list;
        this.version = version;
    }

    url(asset) {
        let url = ASSETS_BASE_URL;

        if (asset in this.list) {
            url += this.list[asset];
        } else {
            url += `${asset}?${this.version}`;
        }

        return url;
    }
}
